.main-wrapper {
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.sections label {
  margin-left: 10px;
  margin-right: 10px;
}

.AddConfigs {
  width: 90%;
  margin: 20px auto;
}

.viewConfigs {
  width: 90%;
  margin: 20px auto;
}

.validBades {
  width: 100%;
  margin: 10px auto;
  text-align: left;
  font-size: 0.70em;
}

/* Change color on hover */
.sections:hover {
  color: rgb(2, 172, 2);
}

.submitRequest {
  text-align: left;
  width: 350px;
  display: inline-block;
}

.name {
  margin-left: 10px;
  margin-right: 10px;
  font-size: large;
}

.desc {
  font-size: large;
}

.navbar {
  width: 100%;
  background: #152939;
  display: inline-block;
  padding: 0px;
  padding-top:    0rem; /* 0px does not look good on small screens */
  padding-bottom: 0rem;
}
.nav-item{
   color:white; 
  
}

.navbar-brand {
  color: #fff;
  font-size: 1.2rem;
  font-family: Amazon Ember Light,Helvetica Neue,Arial,Helvetica,Roboto,sans-serif;
  font-weight: 900;
  padding: 5px 0px 2px 19px;
  margin-left: -510px;
  /* margin-left:-1080px; */
}

.navbar-user{
  color: #fff;
  font-size: 0.9rem;
  font-family: Amazon Ember,Helvetica Neue,Arial,Helvetica,Roboto,sans-serif;
  font-weight: 500;
  padding: 5px 0px 2px 19px;
}

.navbar-user:hover,.navbar-brand:hover {
  color: rgb(4, 201, 250);
}

.navbar-icon {
  width: 28px;
  padding: 5px 2px 9px 2px;
}
.search-box-container input {
  /* padding: 2px 15px 2px 45px; */
  white-space: nowrap;
  text-align: left;
  float: right;
  
}

.search-box{
  /* width: 150%; */
  /* padding: 5px -4px 3px 99px; */
  white-space: nowrap;
  /* left: 35px; */
  float: right;
}

.search-box-container::after {
  content: "\f002";
  font-family: FontAwesome;
  margin-left: -20px;
  margin-right: 1px;
  text-align: left;
}

.input-group.input-group-unstyled input.form-control {
  -webkit-border-radius: 10px;
  -moz-border-radius: 20px;
  border-radius: 10px;
}
.input-group-unstyled .input-group-addon {
  border-radius: 4px;
  border: 0px;
  background-color: transparent;
}
.panel {
  width: 500px;
}

.panel-heading {
  min-height: 36px;
}

.listOfValues {
  text-align: right;
}
.custom-control {
  text-align: right;
}

.button {
  margin-right: 0px;
}

  .submit{
    margin-right: -85px;
    display: inline-block;
  }

  .modal-popup {
  text-align: right;
}

/* CSS for ON OFF */

.switch {
  position: relative;
  display: inline-block;
  width: 90px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ca2222;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2ab934;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(55px);
  -ms-transform: translateX(55px);
  transform: translateX(55px);
}

/*------ ADDED CSS ---------*/
.on {
  display: none;
}

.on,
.off {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

input:checked + .slider .on {
  display: block;
}

input:checked + .slider .off {
  display: none;
}

/*--------- END --------*/

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.input-group {
  width: 80%;
  margin: 25px;
}

p {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-left: 100px;
}

th {
  /* background: #152938; */
  /* background: #558a91; */
  background: #152939;
  color: white;
  text-align:"center";
  white-space: nowrap;
  font-size: 0.9rem;
  font-family: Amazon Ember Light,Helvetica Neue,Arial,Helvetica,Roboto,sans-serif;
  font-weight: bold;
}

th:hover{
  color: #000000;
}

td:hover{
  color: #558a91;
}

td{
  text-align:"center";
  white-space: nowrap;
  font-size: 0.8rem;
  font-family: Amazon Ember Light,Helvetica Neue,Arial,Helvetica,Roboto,sans-serif;
  font-weight: bold;
}

right-align{
  text-align:"right";
  white-space: nowrap;
}

.pull-right {
  float: right;
}

.link-align {
  display: inline;
  margin-right: 1em;
  font-weight: bold;
}

.view-styling{
  color: rgb(4, 201, 250);
}

.edit-styling{
  /* color:rgb(216, 203, 25); */
  color: #333;

}
.disable-styling{
 /* color:rgb(189, 50, 50); */
  /* color: #FFA07a; */
  color: #F08080;
}

.enable-styling{
  /* color:rgb(78, 189, 50); */
  color: #9ACD32;
  font-weight: 250;
}

.remove-styling{
  /* color:rgb(78, 189, 50); */
  color: #F08080;
  font-weight: 250;
}

.button-styling {
  background: none;
  border: none;
  font-weight: bold;
  
}
.button-styling:hover{
  color:#1038bd;
}

h3{
  font-family: Amazon Ember Light,Helvetica Neue,Arial,Helvetica,Roboto,sans-serif;
  font-weight: bold;
  /* color: #558a91; */
  color: #152939;
  /* color: #333; */
}

h3:hover{
  color: rgb(4, 201, 250);
}

h8{
  text-transform: uppercase;
  font-weight: bold;
}

 li input {
  width: 10px;
  position: relative;
  left: 0px;
  vertical-align: middle;
} 

 label {  
  width: 200px;
  position: relative;
  left: 0px;
  margin : 0px;
  vertical-align: middle;
}  
li div { display: table-cell; vertical-align: middle; list-style-type: none;}

ul {
  list-style-type: none;
}

.group {
  margin-bottom: 20px;
}
.group label {
  font-size: 12px;
}

p{
  font-size: 13px;
}
.textSize{
  font-size: 11px;
  font-weight: bold;
}

.textSize:hover{
  color: #558a91;
}
.textSizeHeader{
  font-size: 15px;
  font-weight: bold;
}
.textSizeHeader:hover{
  color: #558a91;
}
.textSizeSemiHeader{
  font-size: 12px;
  font-weight: bold;
}
.textSizeSemiHeader:hover{
  color: #558a91;
}

.centerAlign{
  text-align:center;
}

.textSizeMegaHeader{
  font-size: 20px;
  font-weight: bold;
  color: #558a91;
}
.textSizeMegaHeader:hover{
  color: rgb(4, 201, 250);
}

.headerInline{
    display: inline;
}

.headerMargin{
  float: right;
}

.headerMarginLeft{
  float: left;
}


.addNewStyling{
  /* color: rgb(4, 201, 250); */
  color: #008080;
}

.upperCaseFont{
  text-transform: uppercase;
}

.wrapContent{
  overflow-x:scroll;
  word-wrap: break-word;
}

.checkboxSpacing {
  margin-bottom: 7px;
}

.validBades:last-child {
  margin-bottom: none;
}

.hrStyling{
  width: auto;
  margin: 100px -15px -80px;
  border: 0;
  border-top: 2px solid #c9c7c7;
}

.hrStylingBadges{
  width: auto;
  border: 0;
  border-top: 2px solid #c9c7c7;
}

.searchBarstyle{
  position: relative;
}

input[type="checkbox"][readonly] {
  pointer-events: none !important;
}


/* .matchingLogic {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
  padding: 10px;
} */